<template>
  <mcb-detail-popup :title="title" @esc="cancelHandle">
    <div class="photo">
      <img :src="'data:image/png;base64, ' + item" v-for="(item, key) in photo" :key="key" />
    </div>
  </mcb-detail-popup>
</template>

<script>
import DetailPage from '@/mixins/detail-page.js'

const IMG_MODES = {
  raw: 'Сырое Фото',
  fio: 'Фото ФИО',
  prescription: 'Фото назначения'
}

export default {
  props: {
    prescriptionId: {
      type: Number
    },
    mode: {
      type: String
    },
  },

  mixins: [DetailPage],

  data () {
    return {
      photo: []
    }
  },

  created () {
    this.$wait.show()
    this.$axios.get('/statistic/' + this.prescriptionId + '/mode/' + this.mode)
      .then(response => {
        this.photo = response.data.photo || []
      })
      .catch(err => {
        if (err.response && err.response.data.error) {
          this.$notify.error({title: 'Error',  message: err.response.data.error})
        }
        this.cancelHandle()
      })
      .finally(() => {
        this.$wait.hide()
      })
  },

  computed: {
    title () {
      return IMG_MODES[this.mode]
    }
  }
}
</script>
